import { Injectable, inject, signal } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthService,
  TokenObtainPair,
  TokenObtainPairRequest,
  UserMe,
  UsersService,
} from 'gen';
import { UserStoredInfo } from '../../interfaces/user-stored-info';
import { catchError, EMPTY, map, Observable, switchMap, tap } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';
import { getItemStorage, setItemStorage } from '@shared/helpers';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private readonly router = inject(Router);
  private readonly authService = inject(AuthService);
  private readonly userService = inject(UsersService);

  readonly isAuthenticated = signal(false);

  readonly isAuthenticated$ = toObservable(this.isAuthenticated);

  constructor() {
    this.isAuthenticated.set(this.hasValidAccessToken());

    if (!this.hasValidAccessToken()) {
      this.logout();
    }
  }

  get refresh_Token() {
    return getItemStorage('refreshToken', false);
  }

  get access_token() {
    return getItemStorage('access_token', false);
  }

  get current_user() {
    return getItemStorage<UserStoredInfo>('current_user');
  }

  refreshToken() {
    return this.authService
      .authTokenRefreshCreate({
        tokenRefreshRequest: { refresh_token: this.refresh_Token },
      })
      .pipe(
        map(tokenResponse => {
          setItemStorage('accessToken', tokenResponse.access_token);
          this.isAuthenticated.set(this.hasValidAccessToken());
          this.router.navigate(['/']);
        }),
        catchError(() => {
          this.logout();
          return EMPTY;
        })
      );
  }

  hasValidAccessToken() {
    const accessToken = this.access_token;
    if (accessToken) {
      const expiresAt = this.getClaim('exp', accessToken);
      const now = new Date();
      if (expiresAt && parseInt(expiresAt, 10) * 1000 < now.getTime()) {
        return false;
      }
      return true;
    }
    return false;
  }

  getClaim(property: string, accessToken: string) {
    const tokenParts = accessToken.split('.');
    const claimsBase64 = this.padBase64(tokenParts[1]);
    const claimsJson = this.b64DecodeUnicode(claimsBase64);
    const claims = JSON.parse(claimsJson);
    return claims[property];
  }

  getCurrentUser(): UserStoredInfo {
    return this.current_user as UserStoredInfo;
  }

  updateCurrentUser(user: UserStoredInfo): void {
    setItemStorage('current_user', {
      ...this.current_user,
      ...user,
    });
  }

  padBase64(base64data: string) {
    while (base64data.length % 4 !== 0) {
      base64data += '=';
    }
    return base64data;
  }

  geUserRole(currentUser: UserMe) {
    if (currentUser.is_staff) {
      return 'Admin';
    } else if (!currentUser.is_staff && currentUser.ambassador_of_id) {
      return 'Ambassadeur';
    }
    return 'Membre';
  }

  usersMeRetrieve() {
    return this.userService.usersMeRetrieve().pipe(
      map(currentUser => {
        const userInfo: UserStoredInfo = {
          ...currentUser,
          role: this.geUserRole(currentUser),
        };

        this.authenticateSuccessUser(userInfo, true);
      })
    );
  }

  loginWithPassword(username: string, password: string): Observable<void> {
    const tokenObtainPairRequest: TokenObtainPairRequest = {
      username,
      password,
    };
    return this.authService
      .authTokenCreate({ tokenObtainPairRequest: tokenObtainPairRequest })
      .pipe(
        tap(tokenObtainPairRequest => {
          this.authenticateSuccess(tokenObtainPairRequest, true);
          this.isAuthenticated.set(this.hasValidAccessToken());
        }),
        switchMap(() => this.usersMeRetrieve())
      );
  }

  logout(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('refresh_token');
    sessionStorage.removeItem('current_user');
    sessionStorage.removeItem('current_user');
  }

  b64DecodeUnicode(str: string) {
    const base64 = str.replace(/\-/g, '+').replace(/\_/g, '/');
    return decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }

  private authenticateSuccess(
    tokenObtainPair: TokenObtainPair,
    rememberMe: boolean
  ): void {
    if (rememberMe) {
      localStorage.setItem('access_token', tokenObtainPair.access_token);
      localStorage.setItem('refresh_token', tokenObtainPair.refresh_token);
      sessionStorage.removeItem('access_token');
      sessionStorage.removeItem('refresh_token');
    } else {
      sessionStorage.setItem('access_token', tokenObtainPair.access_token);
      sessionStorage.setItem('refresh_token', tokenObtainPair.refresh_token);
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
    }
  }

  private authenticateSuccessUser(
    userStoredInfo: UserStoredInfo,
    rememberMe: boolean
  ): void {
    if (rememberMe) {
      localStorage.setItem('current_user', JSON.stringify(userStoredInfo));
      sessionStorage.removeItem('current_user');
    } else {
      sessionStorage.setItem('current_user', JSON.stringify(userStoredInfo));
      localStorage.removeItem('current_user');
    }
  }
}
